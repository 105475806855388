import {
	Button,
	FormControlLabel,
	Switch,
	TextField,
	Tooltip,
} from "@material-ui/core";
import { CloudUpload, Delete, Restore, Visibility } from "@material-ui/icons";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import { Autocomplete } from "@material-ui/lab";
import React, { useEffect, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import {
	Card,
	CardBody,
	CardHeader,
	CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import { SERVER_URL } from "../../../../api";
import { getArtists } from "../../../../api/artist";
import { changeStatusArtwork, getArtworks } from "../../../../api/artwork";
import { getArtworkTypes } from "../../../../api/artworkType";
import { getCountries } from "../../../../api/country";
import { uploadSingleFile } from "../../../../api/files";
import { postLog } from "../../../../api/log";
import { getSuppliers } from "../../../../api/supplier";
import { LOGS } from "../../../../utils/constants";
import { alertError, alertSuccess } from "../../../../utils/logger";
import ConfirmDialog from "../../../components/dialogs/ConfirmDialog";
import ImageCarouselDialog from "../../../components/dialogs/ImageCarouselDialog";
import PreviewDialog from "../../../components/dialogs/PreviewDialog";
import Table, {
	buttonsStyle,
	dateFormatter,
	getCurrentTablePage,
} from "../../../components/tables/table";

function getData(artworks) {
	let data = [];
	for (let i = 0; i < artworks.length; ++i) {
		const elem = {};
		const artwork = artworks[i];

		elem.title = artwork?.title;
		elem.artworkType = artwork?.artworkType?.title;
		elem.imagesURLs = [
			artwork?.imageURL,
			...(artwork?.artworkSuppliers?.flatMap((x) => x.imagesURLs) || []),
		];
		elem.artist = artwork?.artist?.fullName;
		elem.createdAt = artwork?.createdAt;
		elem.id = artwork?._id;

		data = data.concat(elem);
	}
	return data;
}

const initialFilters = {
	deleted: false,
	artworkType: "",
	artist: "",
	originCountry: "",
};

export default function ArtworksPage() {
	const [data, setData] = useState([]);
	const [filteredData, setFilteredData] = useState([]);
	const [filteredDataByImage, setFilteredDataByImage] = useState([]);

	const [selectedArtwork, setSelectedArtwork] = useState(null);

	const [artworkTypes, setArtworkTypes] = useState([]);
	const [artists, setArtists] = useState([]);
	const [countries, setCountries] = useState([]);
	const [suppliers, setSuppliers] = useState([]);

	const [openPreviewDialog, setOpenPreviewDialog] = useState(false);
	const [openConfirmDialog, setOpenConfirmDialog] = useState(null);
	const [previewImages, setPreviewImages] = useState(null);

	const [selectedImage, setSelectedImage] = useState(null);
	const [prevSelectedImage, setPrevSelectedImage] = useState(null);

	const [filterOptions, setFilterOptions] = useState(initialFilters);

	const [refresh, setRefresh] = useState(false);
	const [loading, setLoading] = useState(false);

	const location = useLocation();

	const history = useHistory();
	const loggedUser = useSelector(
		(store) => store.authentication?.user,
		shallowEqual
	);

	function imageFormatter(imagesURLs) {
		return imagesURLs && imagesURLs?.length > 0 ? (
			<img
				src={SERVER_URL + "/" + imagesURLs[0]}
				alt="artwork"
				style={{ width: "50px", cursor: "zoom-in" }}
				onClick={() => {
					setPreviewImages(imagesURLs);
					setOpenPreviewDialog(1);
				}}
			/>
		) : (
			<div />
		);
	}

	function buttonFormatter(cell) {
		const elem = data.find((item) => item._id === cell);
		return (
			<>
				<Tooltip title="View">
					<Button
						style={buttonsStyle}
						size="small"
						onClick={() => {
							history.push({
								pathname: "/artworks/" + cell,
								state: {
									page: getCurrentTablePage(location),
								},
							});
						}}
					>
						<Visibility />
					</Button>
				</Tooltip>
				{filterOptions?.deleted ? (
					<Tooltip title="Restore">
						<Button
							style={buttonsStyle}
							size="small"
							onClick={() => {
								setSelectedArtwork(elem);
								setOpenConfirmDialog(2);
							}}
						>
							<Restore />
						</Button>
					</Tooltip>
				) : (
					<>
						<Tooltip title="Edit">
							<Button
								style={buttonsStyle}
								size="small"
								onClick={() => {
									history.push({
										pathname: "/artworks/" + cell + "/edit",
										state: {
											page: getCurrentTablePage(location),
										},
									});
								}}
							>
								<EditIcon />
							</Button>
						</Tooltip>
						<Tooltip title="Delete">
							<Button
								style={buttonsStyle}
								size="small"
								onClick={() => {
									setSelectedArtwork(elem);
									setOpenConfirmDialog(1);
								}}
							>
								<DeleteIcon />
							</Button>
						</Tooltip>
					</>
				)}
			</>
		);
	}

	const columns = [
		{
			dataField: "imagesURLs",
			text: "",
			formatter: imageFormatter,
		},
		{ dataField: "title", text: "Title", sort: true },
		{
			dataField: "artworkType",
			text: "Artwork Type",
			sort: true,
			headerAlign: "center",
			align: "center",
		},
		{
			dataField: "artist",
			text: "Artist",
			sort: true,
			headerAlign: "center",
			align: "center",
		},
		{
			dataField: "createdAt",
			text: "Created At",
			formatter: dateFormatter,
			sort: true,
			headerAlign: "center",
			align: "center",
		},
		{
			dataField: "id",
			text: "",
			formatter: buttonFormatter,
		},
	];

	useEffect(() => {
		getArtworkTypes()
			.then((res) => {
				if (res.status === 200) {
					setArtworkTypes(res.data);
				}
			})
			.catch((error) => {
				alertError({
					error: error,
					customMessage: "Could not get artwork types.",
				});
			});
		getSuppliers()
			.then((res) => {
				if (res.status === 200) {
					setSuppliers(res.data);
				}
			})
			.catch((error) => {
				alertError({
					error: error,
					customMessage: "Could not get suppliers.",
				});
			});
		getArtists()
			.then((res) => {
				if (res.status === 200) {
					setArtists(res.data);
				}
			})
			.catch((error) => {
				alertError({
					error: error,
					customMessage: "Could not get artists.",
				});
			});
		getCountries()
			.then((res) => {
				if (res.status === 200) {
					setCountries(res.data);
				}
			})
			.catch((error) => {
				alertError({
					error: error,
					customMessage: "Could not get countries.",
				});
			});
		getArtworks(null, true)
			.then((res) => {
				if (res.status === 200) {
					setData(res.data);
					setFilteredData(
						res.data?.filter(
							(x) => filterOptions?.deleted === x.deleted
						)
					);
					setRefresh(false);
				}
			})
			.catch((error) => {
				alertError({
					error: error,
					customMessage: "Could not get artworks.",
				});
			});
	}, [refresh]);

	useEffect(() => {
		handleSearch();
	}, [filterOptions, selectedImage]);

	const handleSearch = async () => {
		function filterArtworks(artworks) {
			return [...artworks].filter((item) => {
				let filter = true;
				filter = filter && filterOptions.deleted === item?.deleted;
				if (filterOptions.artworkType)
					filter =
						filter &&
						filterOptions.artworkType?._id ===
							item?.artworkType?._id;

				if (filterOptions.artist)
					filter =
						filter &&
						filterOptions.artist?._id === item?.artist?._id;

				if (filterOptions.originCountry)
					filter =
						filter &&
						filterOptions.originCountry?._id ===
							item?.originCountry?._id;

				if (filter) return item;
				return false;
			});
		}

		if (!data.length) return;

		if (selectedImage && selectedImage !== prevSelectedImage) {
			setLoading(true);
			const response = await uploadSingleFile(selectedImage, "temp");
			const imageURL = response?.data?.fileURL;
			getArtworks(imageURL)
				.then((res) => {
					if (res.status === 200) {
						setPrevSelectedImage(selectedImage);
						let filteredArtworks = filterArtworks(res.data);
						setFilteredData(filteredArtworks);
						setFilteredDataByImage(res.data);
						setLoading(false);
					}
				})
				.catch(() => {
					setLoading(false);
				});
		} else {
			if (selectedImage && selectedImage === prevSelectedImage) {
				setFilteredData(filterArtworks(filteredDataByImage));
			} else setFilteredData(filterArtworks(data));
		}
	};

	const handleClearFilters = () => {
		setFilterOptions(initialFilters);
		setSelectedImage(null);
		setPrevSelectedImage(null);
		setFilteredDataByImage([]);
	};

	function renderFilterContent() {
		const handleDrop = (event) => {
			event.preventDefault();
			setSelectedImage(event.dataTransfer.files[0]);
		};

		const handleDragOver = (event) => {
			event.preventDefault();
		};

		return (
			<>
				<div className="row d-flex align-items-center">
					<div className="col-md-3">
						<Autocomplete
							id="autocomplete-artworkType"
							disablePortal
							options={artworkTypes}
							getOptionLabel={(option) => option.title}
							value={filterOptions?.artworkType}
							onChange={(event, selected) => {
								setFilterOptions({
									...filterOptions,
									artworkType: selected,
								});
							}}
							renderInput={(params) => (
								<TextField
									{...params}
									className="filter-field"
									label="Artwork Type"
									margin="normal"
									variant="outlined"
									InputLabelProps={{
										shrink: true,
									}}
								/>
							)}
						/>
					</div>
					<div className="col-md-3">
						<Autocomplete
							id="autocomplete-artist"
							disablePortal
							options={artists}
							getOptionLabel={(option) => option?.fullName}
							value={filterOptions?.artist}
							onChange={(event, selected) => {
								setFilterOptions({
									...filterOptions,
									artist: selected,
								});
							}}
							renderInput={(params) => (
								<TextField
									{...params}
									className="filter-field"
									label="Artist"
									margin="normal"
									variant="outlined"
									InputLabelProps={{
										shrink: true,
									}}
								/>
							)}
						/>
					</div>
					<div className="col-md-3">
						<Autocomplete
							id="autocomplete-country"
							disablePortal
							options={countries}
							getOptionLabel={(option) => option?.name}
							value={filterOptions?.originCountry}
							onChange={(event, selected) => {
								setFilterOptions({
									...filterOptions,
									originCountry: selected,
								});
							}}
							renderInput={(params) => (
								<TextField
									{...params}
									className="filter-field"
									label="Origin Country"
									margin="normal"
									variant="outlined"
									InputLabelProps={{
										shrink: true,
									}}
								/>
							)}
						/>
					</div>
					<div className="col-md-3">
						<FormControlLabel
							control={
								<Switch
									checked={filterOptions.deleted}
									onChange={() =>
										setFilterOptions({
											...filterOptions,
											deleted: !filterOptions.deleted,
										})
									}
								/>
							}
							label="Deleted"
						/>
					</div>
					<div className="col-12 d-flex align-items-center">
						<label htmlFor={"upload-image"} className="ml-0">
							<input
								key={
									selectedImage
										? selectedImage.lastModified
										: ""
								}
								style={{ display: "none" }}
								id={"upload-image"}
								name={"upload-image"}
								type="file"
								accept={"image/*"}
								onChange={(e) => {
									setSelectedImage(e.target.files[0]);
								}}
							/>
							<div
								id="drop-zone"
								class="d-flex justify-content-center align-items-center p-3"
								style={{
									border: "2px dashed #c6c6c6",
									height: "40px",
									cursor: "pointer",
								}}
								onDrop={handleDrop}
								onDragOver={handleDragOver}
							>
								<CloudUpload className="mr-2" />
								Upload or drop image here
							</div>
						</label>
						<div className="mb-2 d-flex align-items-center">
							{selectedImage && (
								<>
									<Tooltip title={"Preview"}>
										<Button
											size="small"
											onClick={() =>
												setOpenPreviewDialog(2)
											}
											style={{
												...buttonsStyle,
												marginRight: "15px",
											}}
										>
											<Visibility />
										</Button>
									</Tooltip>
									<PreviewDialog
										title={"Preview"}
										open={openPreviewDialog === 2}
										setOpen={setOpenPreviewDialog}
										file={selectedImage}
										src={
											selectedImage &&
											URL.createObjectURL(selectedImage)
										}
									/>
									<span>
										{selectedImage
											? selectedImage?.name
											: ""}
									</span>
									{loading ? (
										<span
											className="spinner-border ml-3"
											role="status"
										></span>
									) : (
										<Tooltip title={"Delete"}>
											<Button
												size="small"
												onClick={() => {
													setSelectedImage(null);
												}}
											>
												<Delete />
											</Button>
										</Tooltip>
									)}
								</>
							)}
						</div>
					</div>
				</div>
			</>
		);
	}

	function exportArtworksToCSV() {
		const date = new Date();
		const year = date.getFullYear();
		const month = String(date.getMonth() + 1).padStart(2, "0");
		const day = String(date.getDate()).padStart(2, "0");
		const formatted = `${year}-${month}-${day}`;

		const fileName = `${formatted}-artworks`;

		let csvHeaders = [
			"TITLE",
			"ARTWORK TYPE",
			"ARTIST",
			"SIZE",
			"TECHNIQUE",
			"YEAR",
			"CONSERVATION STATUS",
			"SIGNATURE",
			"ORIGIN COUNTRY",
			"SUBJECT",
			"CURRENT LOCATION",
			"PROVENANCE",
			"CREATED AT",
		];

		const exportData = data?.filter((x) => !x?.deleted);

		const maxSuppliers = Math.max(
			...exportData.map(
				(artwork) => artwork.artworkSuppliers?.length || 0
			)
		);

		for (let i = 1; i <= maxSuppliers; i++) {
			csvHeaders.push(`SUPPLIER ${i}`);
			csvHeaders.push(`SUPPLIER ${i} COMMENTS`);
		}

		let csvFile = `${csvHeaders.join(";")}\r\n`;

		exportData.forEach((artwork) => {
			const baseRow = [
				artwork?.title,
				artwork?.artworkType?.title,
				artwork?.artist?.fullName,
				artwork?.size,
				artwork?.technique,
				artwork?.year,
				artwork?.conservationStatus,
				artwork?.signature,
				artwork?.originCountry?.name,
				artwork?.subject,
				artwork?.currentLocation,
				artwork?.provenance,
				artwork?.createdAt,
			];

			const supplierData = [];
			if (artwork.artworkSuppliers) {
				artwork.artworkSuppliers.forEach((artworkSupplier) => {
					const supplier = suppliers?.find(
						(x) => x?._id === artworkSupplier?.supplier
					);

					supplierData.push(supplier?.fullName || "");
					supplierData.push(
						artworkSupplier?.comments?.replace(/<[^>]+>/g, "")
					);
				});
			}

			csvFile += `${[...baseRow, ...supplierData].join(";")}\r\n`;
		});

		const blob = new Blob([csvFile], { type: "text/csv;charset=utf-8;" });
		if (navigator.msSaveBlob) {
			navigator.msSaveBlob(blob, fileName);
		} else {
			const link = document.createElement("a");
			if (link.download !== undefined) {
				const url = URL.createObjectURL(blob);
				link.setAttribute("href", url);
				link.setAttribute("download", fileName);
				link.style.visibility = "hidden";
				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);
			}
		}
	}

	return (
		<>
			<Card>
				<CardHeader title="Artwork list">
					<CardHeaderToolbar>
						<button
							type="button"
							className="btn btn-secondary mr-3"
							onClick={() => {
								exportArtworksToCSV();
							}}
						>
							Export
						</button>
						<button
							type="button"
							className="btn btn-primary"
							onClick={() => {
								history.push({
									pathname: "/artworks/edit",
									state: {
										page: getCurrentTablePage(location),
									},
								});
							}}
						>
							Add new
						</button>
					</CardHeaderToolbar>
				</CardHeader>
				<CardBody>
					<Table
						data={getData(filteredData)}
						columns={columns}
						filters={renderFilterContent()}
						resetFilters={handleClearFilters}
						allowExport={true}
					/>

					<ConfirmDialog
						title={"Are you sure you want to delete this artwork?"}
						open={openConfirmDialog === 1}
						setOpen={setOpenConfirmDialog}
						onConfirm={() => {
							changeStatusArtwork(selectedArtwork?._id, true)
								.then((res) => {
									if (res.status === 200) {
										postLog(
											loggedUser,
											LOGS.FIELDS.ARTWORKS,
											`${LOGS.ACTIONS.DELETE}-${selectedArtwork?.title}`
										);
										alertSuccess({
											title: "Deleted!",
											customMessage:
												"Artwork deleted successfully.",
										});
										setRefresh(true);
										handleSearch();
									}
								})
								.catch((error) => {
									alertError({
										error: error,
										customMessage:
											"Could not delete artwork.",
									});
								});
						}}
					/>
					<ConfirmDialog
						title={"Are you sure you want to restore this artwork?"}
						open={openConfirmDialog === 2}
						setOpen={setOpenConfirmDialog}
						onConfirm={() => {
							changeStatusArtwork(selectedArtwork?._id, false)
								.then((res) => {
									if (res.status === 200) {
										postLog(
											loggedUser,
											LOGS.FIELDS.ARTWORKS,
											`${LOGS.ACTIONS.RESTORE}-${selectedArtwork?.title}`
										);
										alertSuccess({
											title: "Deleted!",
											customMessage:
												"Artwork restored successfully.",
										});
										setRefresh(true);
									}
								})
								.catch((error) => {
									alertError({
										error: error,
										customMessage:
											"Could not restore artwork.",
									});
								});
						}}
					/>
					<ImageCarouselDialog
						open={openPreviewDialog === 1}
						setOpen={setOpenPreviewDialog}
						images={previewImages}
					/>
				</CardBody>
			</Card>
		</>
	);
}
