import {
	Button,
	Checkbox,
	createMuiTheme,
	FormControlLabel,
	MuiThemeProvider,
	TextField,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import React, { useEffect, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import {
	Card,
	CardBody,
	CardHeader,
} from "../../../../_metronic/_partials/controls";
import { postLog } from "../../../../api/log";
import {
	deleteUser,
	getUserById,
	postUser,
	updateUser,
} from "../../../../api/user";
import { LOGS, ROLES } from "../../../../utils/constants";
import { alertError, alertSuccess } from "../../../../utils/logger";
import ConfirmDialog from "../../../components/dialogs/ConfirmDialog";
import { useSkeleton } from "../../../hooks/useSkeleton";

// Create theme for delete button (red)
const theme = createMuiTheme({
	palette: {
		secondary: {
			main: "#F64E60",
		},
	},
});

function getEmptyAdmin() {
	return {
		fullName: "",
		email: "",
		role: "admin",
		password: "",
		repeatPassword: "",
		active: true,
	};
}

export default function EditAdminsPage() {
	const [admin, setAdmin] = useState(getEmptyAdmin());
	const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
	const [newPassword, setNewPassword] = useState({
		password: null,
		repeatPassword: null,
	});
	const [changePassword, setChangePassword] = useState(false);
	const adminId = useParams().id;
	const history = useHistory();
	const location = useLocation();

	const loggedUser = useSelector(
		(store) => store.authentication?.user,
		shallowEqual
	);

	const {
		isLoading: isLoadingData,
		disableLoading: disableLoadingData,
		ContentSkeleton,
	} = useSkeleton();

	useEffect(() => {
		if (!adminId) {
			disableLoadingData();
			return;
		}
		getUserById(adminId)
			.then((res) => {
				if (res.status === 200) {
					const user = res.data;
					delete user.password;
					setAdmin(user);
					disableLoadingData();
				}
			})
			.catch((error) => {
				alertError({
					error: error,
					customMessage: "Could not get admin.",
				});
				handleBack();
			});
	}, [adminId, disableLoadingData, history]);

	const handleBack = () => {
		const prevTablePage = location?.state?.page;

		history.push(`/admins${prevTablePage ? "?page=" + prevTablePage : ""}`);
	};

	function saveAdmin() {
		let saveAdmin = admin;
		if (!adminId || changePassword) {
			if (!newPassword.password || !newPassword.repeatPassword) {
				alertError({
					error: null,
					customMessage: "Please enter the password.",
				});
				return;
			}
			if (newPassword.password !== newPassword.repeatPassword) {
				alertError({
					error: null,
					customMessage: "Passwords do not match.",
				});
				return;
			}
			saveAdmin = { ...saveAdmin, password: newPassword.password };
		}
		if (!adminId) {
			postUser(saveAdmin)
				.then((res) => {
					if (res.status === 201) {
						postLog(
							loggedUser,
							LOGS.FIELDS.ADMINS,
							`${LOGS.ACTIONS.CREATE}-${saveAdmin?.fullName}`
						);
						alertSuccess({
							title: "Saved!",
							customMessage: "Admin created successfully.",
						});
						handleBack();
					}
				})
				.catch((error) => {
					alertError({
						error: error,
						customMessage: "Could not save admin.",
					});
				});
		} else {
			updateUser(adminId, saveAdmin)
				.then((res) => {
					if (res.status === 200) {
						postLog(
							loggedUser,
							LOGS.FIELDS.ADMINS,
							`${LOGS.ACTIONS.UPDATE}-${saveAdmin?.fullName}`
						);
						alertSuccess({
							title: "Saved!",
							customMessage: "Changes successfully saved.",
						});
						handleBack();
					}
				})
				.catch((error) => {
					alertError({
						error: error,
						customMessage: "Could not save changes.",
					});
				});
		}
	}

	const handleChange = (element) => (event) => {
		setAdmin({ ...admin, [element]: event.target.value });
	};

	if (isLoadingData) return <ContentSkeleton />;
	else
		return (
			<>
				<Card>
					<CardHeader title="Edit admin"></CardHeader>
					<CardBody>
						<TextField
							id={`fullName`}
							label="Name"
							value={admin.fullName}
							onChange={handleChange("fullName")}
							InputLabelProps={{
								shrink: true,
							}}
							margin="normal"
							variant="outlined"
							required
						/>
						<TextField
							id={`email`}
							label="Email"
							value={admin.email}
							onChange={handleChange("email")}
							InputLabelProps={{
								shrink: true,
							}}
							margin="normal"
							variant="outlined"
							required
						/>
						<Autocomplete
							id="autocomplete-role"
							disablePortal
							disableClearable
							filterSelectedOptions
							options={Object.values(ROLES)}
							getOptionLabel={(option) =>
								option.charAt(0).toUpperCase() + option.slice(1)
							}
							value={admin?.role || ""}
							onChange={(event, selected) => {
								setAdmin({
									...admin,
									role: selected ? selected : null,
								});
							}}
							renderInput={(params) => (
								<TextField
									{...params}
									label="Rol"
									margin="normal"
									variant="outlined"
									InputLabelProps={{
										shrink: true,
									}}
									required
								/>
							)}
						/>
						{!adminId || changePassword ? (
							<>
								<br />
								<br />
								<div className="row d-flex align-items-center">
									<div className="col-md-6">
										<TextField
											id={`password`}
											label="Contraseña"
											value={newPassword.password}
											onChange={(event) => {
												if (event.target.value !== " ")
													setNewPassword({
														...newPassword,
														password:
															event.target.value,
													});
											}}
											InputLabelProps={{
												shrink: true,
											}}
											type="password"
											margin="normal"
											variant="outlined"
											required
										/>
									</div>
									<div className="col-md-6">
										<TextField
											id={`repeatPassword`}
											label="Repetir contraseña"
											value={newPassword.repeatPassword}
											onChange={(event) => {
												if (event.target.value !== " ")
													setNewPassword({
														...newPassword,
														repeatPassword:
															event.target.value,
													});
											}}
											InputLabelProps={{
												shrink: true,
											}}
											type="password"
											margin="normal"
											variant="outlined"
											required
										/>
									</div>
								</div>
								<br />
								<br />
								{adminId && (
									<>
										<Button
											onClick={() => {
												setChangePassword(false);
												setNewPassword({
													password: null,
													repeatPassword: null,
												});
											}}
											variant="outlined"
											style={{ marginRight: "20px" }}
										>
											Cancel
										</Button>
										<br />
										<br />
									</>
								)}
							</>
						) : (
							<>
								<br />
								<br />
								<Button
									onClick={() => setChangePassword(true)}
									variant="outlined"
									color="primary"
									style={{ marginRight: "20px" }}
								>
									Cambiar contraseña
								</Button>
								<br />
								<br />
							</>
						)}
						<br />
						<FormControlLabel
							control={
								<Checkbox
									checked={admin.active}
									onChange={() =>
										setAdmin({
											...admin,
											active: !admin.active,
										})
									}
									name="checkActive"
								/>
							}
							label="Activo"
						/>
					</CardBody>
				</Card>
				<Button
					onClick={handleBack}
					variant="outlined"
					style={{ marginRight: "20px" }}
				>
					Back
				</Button>
				<Button
					onClick={() => saveAdmin()}
					variant="outlined"
					color="primary"
					style={{ marginRight: "20px" }}
				>
					Save admin
				</Button>
				{adminId && (
					<>
						<MuiThemeProvider theme={theme}>
							<Button
								className="py-2"
								onClick={() => setOpenConfirmDialog(true)}
								variant="outlined"
								color="secondary"
							>
								Delete admin
							</Button>
						</MuiThemeProvider>

						<ConfirmDialog
							title={
								"Are you sure you want to delete this admin?"
							}
							open={openConfirmDialog}
							setOpen={setOpenConfirmDialog}
							onConfirm={() => {
								deleteUser(adminId)
									.then((res) => {
										if (
											res.status === 204 ||
											res.status === 200
										) {
											postLog(
												loggedUser,
												LOGS.FIELDS.ADMINS,
												`${LOGS.ACTIONS.DELETE}-${admin?.fullName}`
											);
											alertSuccess({
												title: "Deleted!",
												customMessage:
													"Admin deleted successfully.",
											});
											handleBack();
										}
									})
									.catch((error) => {
										alertError({
											error: error,
											customMessage:
												"Could not delete admin.",
										});
									});
							}}
						/>
					</>
				)}
			</>
		);
}
