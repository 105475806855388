import { Dialog, makeStyles } from "@material-ui/core";
import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { SERVER_URL } from "../../../api";
import "../../../customStyles/image_carousel.css";

const useStyles = makeStyles(() => ({
	img: {
		display: "block",
		width: "100%",
		maxHeight: "80vh",
		objectFit: "contain",
		objectPosition: "center",
		margin: "auto",
	},
	dialogContent: {
		padding: 30,
		overflow: "hidden",
	},
}));

const ImageCarouselDialog = ({ open, setOpen, images }) => {
	const classes = useStyles();
	const handleClose = () => {
		setOpen(false);
	};

	const settings = {
		dots: images?.length > 1,
		infinite: images?.length > 1,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
	};

	return (
		<Dialog
			open={open}
			onClose={handleClose}
			fullWidth
			maxWidth="md"
			classes={{ paper: classes.dialogContent }}
		>
			<div className="image-slider-container">
				<Slider {...settings}>
					{images?.map((image, index) => (
						<div key={index}>
							<img
								src={
									typeof image === "string"
										? `${SERVER_URL}/${image}`
										: image && URL.createObjectURL(image)
								}
								alt={`Artwork Image ${index}`}
								className={classes.img}
							/>
						</div>
					))}
				</Slider>
			</div>
		</Dialog>
	);
};

export default ImageCarouselDialog;
