import {
	Button,
	createMuiTheme,
	MuiThemeProvider,
	TextField,
	Tooltip,
} from "@material-ui/core";
import {
	AddComment,
	AddPhotoAlternate,
	Comment,
	Delete,
	Edit,
	InsertDriveFile,
	Launch,
	NoteAdd,
	PhotoLibrary,
	RateReview,
	Visibility,
} from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import {
	Card,
	CardBody,
	CardHeader,
	CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import { SERVER_URL } from "../../../../api";
import { getArtworks } from "../../../../api/artwork";
import { postLog } from "../../../../api/log";
import {
	deleteSupplier,
	getSupplierById,
	postSupplier,
	updateSupplier,
} from "../../../../api/supplier";
import { LOGS } from "../../../../utils/constants";
import { alertError, alertSuccess } from "../../../../utils/logger";
import ArtworkTableDialog from "../../../components/dialogs/ArtworkTableDialog";
import CommentDialog from "../../../components/dialogs/CommentDialog";
import ConfirmDialog from "../../../components/dialogs/ConfirmDialog";
import EditContactDialog from "../../../components/dialogs/EditContactDialog";
import FilePickerDialog from "../../../components/dialogs/FilePickerDialog";
import ImageCarouselDialog from "../../../components/dialogs/ImageCarouselDialog";
import ImagePickerDialog from "../../../components/dialogs/ImagePickerDialog";
import PreviewDialog from "../../../components/dialogs/PreviewDialog";
import Editor from "../../../components/editor/Editor";
import ItemCountBadge from "../../../components/ItemCountBadge";
import Table, {
	buttonsStyle,
	substringFormatter,
} from "../../../components/tables/table";
import { useSkeleton } from "../../../hooks/useSkeleton";

// Create theme for delete button (red)
const theme = createMuiTheme({
	palette: {
		secondary: {
			main: "#F64E60",
		},
	},
});

function getEmptySupplier() {
	return {
		fullName: "",
		nif: null,
		email: null,
		phone: null,
		address: null,
		postalCode: null,
		town: null,
		province: null,
		country: null,
		web: null,
		comments: null,
		contacts: [],
		artworkDesires: [],
		imagesURLs: [],
		filesURLs: [],
		images: [],
		files: [],
	};
}

function getArtworkSupplierData(artworks) {
	let data = [];
	for (let i = 0; i < artworks?.length; ++i) {
		const elem = {};
		const artwork = artworks[i];

		elem.images = [
			artwork?.imageURL,
			...(artwork?.artworkSuppliers?.flatMap((x) => x.imagesURLs) || []),
		]?.filter((x) => x);
		elem.title = artwork?.title;
		elem.artworkType = artwork?.artworkType?.title;
		elem.artist = artwork?.artist?.fullName;
		elem.deleted = artwork?.deleted;
		elem.id = artwork?._id;

		data = data.concat(elem);
	}
	return data;
}

function getContactData(contacts) {
	let data = [];
	for (let i = 0; i < contacts.length; ++i) {
		const elem = {};
		const contact = contacts[i];

		elem.fullName = contact?.fullName;
		elem.email = contact?.email;
		elem.phone = contact?.phone;
		elem.createdAt = contact?.createdAt;
		elem.id = contact?.id || i;

		data = data.concat(elem);
	}
	return data;
}

export default function EditSuppliersPage() {
	const [supplier, setSupplier] = useState(getEmptySupplier());
	const [contacts, setContacts] = useState([]);
	const [artworks, setArtworks] = useState([]);

	const [selectedArtworkSupplier, setSelectedArtworkSupplier] = useState(
		null
	);

	const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
	const [openArtworkTableDialog, setOpenArtworkTableDialog] = useState(false);
	const [openEditContactDialog, setOpenEditContactDialog] = useState(false);

	const [selectedContact, setSelectedContact] = useState(null);

	const [openPreviewDialog, setOpenPreviewDialog] = useState(false);
	const [selectedImages, setSelectedImages] = useState([]);
	const [selectedFiles, setSelectedFiles] = useState([]);

	const [carouselImages, setCarouselImages] = useState([]);
	const [openCarouselDialog, setOpenCarouselDialog] = useState(false);

	const [openViewCommentDialog, setOpenViewCommentDialog] = useState(false);
	const [openViewImagesDialog, setOpenViewImagesDialog] = useState(false);
	const [openViewFilesDialog, setOpenViewFilesDialog] = useState(false);

	const [openCommentDialog, setOpenCommentDialog] = useState(false);
	const [openImagePickerDialog, setOpenImagePickerDialog] = useState(false);
	const [openFilePickerDialog, setOpenFilePickerDialog] = useState(false);

	const supplierId = useParams().id;
	const history = useHistory();
	const location = useLocation();

	const [refresh, setRefresh] = useState(null);

	const loggedUser = useSelector(
		(store) => store.authentication?.user,
		shallowEqual
	);

	const {
		isLoading: isLoadingData,
		disableLoading: disableLoadingData,
		ContentSkeleton,
	} = useSkeleton();

	useEffect(() => {
		getArtworks()
			.then((res) => {
				if (res.status === 200) {
					setArtworks(res.data);
				}
			})
			.catch((error) => {
				alertError({
					error: error,
					customMessage: "Could not get artworks.",
				});
				handleBack();
			});
		if (!supplierId) {
			disableLoadingData();
			return;
		}
		getSupplierById(supplierId)
			.then((res) => {
				if (res.status === 200) {
					let supplierData = {
						...supplier,
						...res.data,
						contacts: getContactData(res.data?.contacts),
					};
					supplierData.artworkDesires.forEach((artworkDesire) => {
						artworkDesire.artwork = artworkDesire?.artwork?._id;
					});
					setSupplier(supplierData);
					disableLoadingData();
				}
			})
			.catch((error) => {
				alertError({
					error: error,
					customMessage: "Could not get supplier.",
				});
				handleBack();
			});
	}, [supplierId, disableLoadingData, history]);

	useEffect(() => {
		setRefresh(false);
	}, [refresh]);

	const handleBack = () => {
		const prevTablePage = location?.state?.page;

		history.push(
			`/suppliers${prevTablePage ? "?page=" + prevTablePage : ""}`
		);
	};

	function saveSupplier() {
		const saveImages = selectedImages?.filter((x) => x?.name);
		const saveFiles = selectedFiles?.filter((x) => x?.name);

		let saveSupplier = {
			...supplier,
			imagesURLs: selectedImages?.filter((x) => !x?.name),
			filesURLs: selectedFiles?.filter((x) => !x?.name),
		};

		if (!supplierId) {
			postSupplier(saveSupplier, saveImages, saveFiles)
				.then((res) => {
					if (res.status === 201) {
						postLog(
							loggedUser,
							LOGS.FIELDS.SUPPLIERS,
							`${LOGS.ACTIONS.CREATE}-${saveSupplier?.fullName}`
						);
						alertSuccess({
							title: "Saved!",
							customMessage: "Supplier created successfully.",
						});
						handleBack();
					}
				})
				.catch((error) => {
					alertError({
						error: error,
						customMessage: "Could not save supplier.",
					});
				});
		} else {
			updateSupplier(supplierId, saveSupplier, saveImages, saveFiles)
				.then((res) => {
					if (res.status === 200) {
						postLog(
							loggedUser,
							LOGS.FIELDS.SUPPLIERS,
							`${LOGS.ACTIONS.UPDATE}-${saveSupplier?.fullName}`
						);
						alertSuccess({
							title: "Saved!",
							customMessage: "Changes successfully saved.",
						});
						handleBack();
					}
				})
				.catch((error) => {
					alertError({
						error: error,
						customMessage: "Could not save changes.",
					});
				});
		}
	}

	const handleChange = (element) => (event) => {
		setSupplier({ ...supplier, [element]: event.target.value });
	};

	const handleChangeEditor = (element, value) => {
		setSupplier({ ...supplier, [element]: value });
	};

	const handleDeleteImage = (index) => {
		let newImages = [...selectedImages];
		newImages.splice(index, 1);
		setSelectedImages(newImages);
	};

	const handleDeleteFile = (index) => {
		let newFiles = [...selectedFiles];
		newFiles.splice(index, 1);
		setSelectedFiles(newFiles);
	};

	function imageFormatterCarousel(images) {
		return images && images?.length > 0 ? (
			<img
				src={
					typeof images[0] === "string"
						? SERVER_URL + "/" + images[0]
						: URL.createObjectURL(images[0])
				}
				alt="artwork"
				style={{ width: "50px", cursor: "zoom-in" }}
				onClick={() => {
					setCarouselImages(images);
					setOpenCarouselDialog(true);
				}}
			/>
		) : (
			<div />
		);
	}

	function offeredArtworksButtonFormatter(cell) {
		const artworkSupplier = supplier?.artworks
			?.find((x) => x._id === cell)
			.artworkSuppliers?.find((x) => x.supplier === supplierId);

		return (
			<>
				<Tooltip title={"Comments"}>
					<Button
						style={buttonsStyle}
						size="small"
						onClick={() => {
							setOpenViewCommentDialog(true);
							setSelectedArtworkSupplier(artworkSupplier);
						}}
					>
						<Comment />
						{artworkSupplier?.comments && (
							<ItemCountBadge howMany={1} />
						)}
					</Button>
				</Tooltip>
				<Tooltip title={"Images"}>
					<Button
						style={buttonsStyle}
						size="small"
						onClick={() => {
							setOpenViewImagesDialog(true);
							setSelectedArtworkSupplier(artworkSupplier);
						}}
					>
						<PhotoLibrary />
						{artworkSupplier?.imagesURLs?.length > 0 && (
							<ItemCountBadge
								howMany={artworkSupplier?.imagesURLs?.length}
							/>
						)}
					</Button>
				</Tooltip>
				<Tooltip title={"Documentation"}>
					<Button
						style={buttonsStyle}
						size="small"
						onClick={() => {
							setOpenViewFilesDialog(true);
							setSelectedArtworkSupplier(artworkSupplier);
						}}
					>
						<InsertDriveFile />
						{artworkSupplier?.filesURLs?.length > 0 && (
							<ItemCountBadge
								howMany={artworkSupplier?.filesURLs?.length}
							/>
						)}
					</Button>
				</Tooltip>
				<Tooltip title={"See artwork"}>
					<Button
						style={buttonsStyle}
						size="small"
						onClick={() => {
							history.push("/artworks/" + cell);
						}}
					>
						<Launch />
					</Button>
				</Tooltip>
			</>
		);
	}

	function getArtworkDesireData(artworkDesires) {
		let data = [];
		for (let i = 0; i < artworkDesires?.length; ++i) {
			const elem = {};
			const artworkDesire = artworkDesires[i];
			const artwork = artworks?.find(
				(x) => x._id === artworkDesire?.artwork
			);

			elem.images = [
				artwork?.imageURL,
				...(artworkDesire?.imagesURLs || []),
			]?.filter((x) => x);
			elem.title = artwork?.title;
			elem.artworkType = artwork?.artworkType?.title;
			elem.artist = artwork?.artist?.fullName;
			elem.comments = artworkDesire?.comments;
			elem.deleted = artwork?.deleted;
			elem.id = artworkDesire?.artwork;

			data = data.concat(elem);
		}
		return data;
	}

	function artworkDesireButtonFormatter(cell) {
		const artworkDesire =
			cell && supplier.artworkDesires?.find((x) => x.artwork === cell);

		return (
			<>
				<Tooltip
					title={
						!artworkDesire?.comments
							? "Add comments"
							: "Edit comments"
					}
				>
					<Button
						style={buttonsStyle}
						size="small"
						onClick={() => {
							setOpenCommentDialog(true);
							setSelectedArtworkSupplier(artworkDesire);
						}}
					>
						{!artworkDesire?.comments ? (
							<AddComment />
						) : (
							<RateReview />
						)}
						{artworkDesire?.comments && (
							<ItemCountBadge howMany={1} />
						)}
					</Button>
				</Tooltip>
				<Tooltip
					title={
						!artworkDesire?.imagesURLs?.length > 0 &&
						!artworkDesire?.images?.length > 0
							? "Add Images"
							: "Edit Images"
					}
				>
					<Button
						style={buttonsStyle}
						size="small"
						onClick={() => {
							setOpenImagePickerDialog(true);
							setSelectedArtworkSupplier(artworkDesire);
						}}
					>
						<AddPhotoAlternate />
						{(artworkDesire?.imagesURLs?.length > 0 ||
							artworkDesire?.images?.length > 0) && (
							<ItemCountBadge
								howMany={
									(artworkDesire?.imagesURLs?.length || 0) +
									(artworkDesire?.images?.length || 0)
								}
							/>
						)}
					</Button>
				</Tooltip>
				<Tooltip
					title={
						!artworkDesire?.filesURLs?.length > 0 &&
						!artworkDesire?.files?.length > 0
							? "Add documentation"
							: "Edit documentation"
					}
				>
					<Button
						style={buttonsStyle}
						size="small"
						onClick={() => {
							setOpenFilePickerDialog(true);
							setSelectedArtworkSupplier(artworkDesire);
						}}
					>
						<NoteAdd />
						{(artworkDesire?.filesURLs?.length > 0 ||
							artworkDesire?.files?.length > 0) && (
							<ItemCountBadge
								howMany={
									(artworkDesire?.filesURLs?.length || 0) +
									(artworkDesire?.files?.length || 0)
								}
							/>
						)}
					</Button>
				</Tooltip>
				<Tooltip title={"See artwork"}>
					<Button
						style={buttonsStyle}
						size="small"
						onClick={() => {
							history.push("/artworks/" + cell);
						}}
					>
						<Launch />
					</Button>
				</Tooltip>
				<Tooltip title="Delete">
					<Button
						style={buttonsStyle}
						size="small"
						onClick={() => {
							let artworkDesires = [...supplier.artworkDesires];
							artworkDesires = artworkDesires.filter(
								(x, index) => x.artwork !== cell
							);
							setSupplier({
								...supplier,
								artworkDesires: artworkDesires,
							});
						}}
					>
						<Delete />
					</Button>
				</Tooltip>
			</>
		);
	}

	function contactButtonFormatter(cell) {
		const elem = supplier?.contacts?.find((item) => item.id === cell);

		return (
			<>
				<Tooltip title="Edit">
					<Button
						style={buttonsStyle}
						size="small"
						onClick={() => {
							setSelectedContact(elem);
							setOpenEditContactDialog(true);
						}}
					>
						<Edit />
					</Button>
				</Tooltip>
				<Tooltip title="Delete">
					<Button
						style={buttonsStyle}
						size="small"
						onClick={() => {
							let contacts = [...supplier?.contacts];

							let index = contacts?.findIndex(
								(x) => x.id === cell
							);

							contacts.splice(index, 1);

							setSupplier({ ...supplier, contacts: contacts });

							setRefresh(true);
						}}
					>
						<Delete />
					</Button>
				</Tooltip>
			</>
		);
	}

	const artworkSuppliersColumns = [
		{
			dataField: "images",
			text: "",
			formatter: imageFormatterCarousel,
		},
		{ dataField: "title", text: "Title", sort: true },
		{
			dataField: "artworkType",
			text: "Artwork Type",
			sort: true,
			headerAlign: "center",
			align: "center",
		},
		{
			dataField: "artist",
			text: "Artist",
			sort: true,
			headerAlign: "center",
			align: "center",
		},
		{
			dataField: "id",
			text: "",
			formatter: offeredArtworksButtonFormatter,
		},
	];

	const artworkDesiresColumns = [
		{
			dataField: "images",
			text: "",
			formatter: imageFormatterCarousel,
		},
		{ dataField: "title", text: "Title", sort: true },
		{
			dataField: "artworkType",
			text: "Artwork Type",
			sort: true,
			headerAlign: "center",
			align: "center",
		},
		{
			dataField: "artist",
			text: "Artist",
			sort: true,
			headerAlign: "center",
			align: "center",
		},
		{
			dataField: "comments",
			text: "Comments",
			sort: true,
			formatter: substringFormatter,
		},
		{ dataField: "id", text: "", formatter: artworkDesireButtonFormatter },
	];

	const contactColumns = [
		{ dataField: "fullName", text: "Name", sort: true },
		{
			dataField: "email",
			text: "Email",
			sort: true,
			headerAlign: "center",
			align: "center",
		},
		{
			dataField: "phone",
			text: "Phone",
			sort: true,
			headerAlign: "center",
			align: "center",
		},
		{ dataField: "id", text: "", formatter: contactButtonFormatter },
	];

	useEffect(() => {
		setSelectedImages([
			...(supplier?.imagesURLs ?? []),
			...(supplier?.images ?? []),
		]);
		setSelectedFiles([
			...(supplier?.filesURLs ?? []),
			...(supplier?.files ?? []),
		]);
	}, [supplier]);

	function renderImagePicker() {
		return (
			<>
				{selectedImages?.map((_, index) => {
					return (
						<>
							<label htmlFor={"upload-image" + index} key={index}>
								<input
									style={{ display: "none" }}
									id={"upload-image" + index}
									name={"upload-image" + index}
									type="file"
									accept={"image/*"}
									onChange={(e) => {
										const file = e.target.files[0];
										setSelectedImages(
											(prevSelectedImages) => {
												const newSelectedImages = [
													...prevSelectedImages,
												];
												newSelectedImages[index] = file;
												return newSelectedImages;
											}
										);
									}}
									key={index}
								/>
								<Button
									style={{ marginRight: "15px" }}
									color="secondary"
									component="span"
									variant="outlined"
								>
									{selectedImages && selectedImages[index]
										? "Change image " + (index + 1)
										: "Upload image " + (index + 1)}
								</Button>
							</label>
							{selectedImages && selectedImages[index] && (
								<>
									<Tooltip
										title={"Preview image " + (index + 1)}
									>
										<Button
											size="small"
											onClick={() =>
												setOpenPreviewDialog(index)
											}
											style={buttonsStyle}
										>
											<Visibility />
										</Button>
									</Tooltip>
									<PreviewDialog
										title={"Preview " + (index + 1)}
										open={openPreviewDialog === index}
										setOpen={setOpenPreviewDialog}
										file={
											selectedImages &&
											selectedImages[index]
										}
										src={
											selectedImages &&
											selectedImages[index] &&
											typeof selectedImages[index] ===
												"string"
												? `${SERVER_URL}/${selectedImages[index]}`
												: URL.createObjectURL(
														selectedImages[index]
												  )
										}
									/>
									<Tooltip title="Delete">
										<Button
											size="small"
											style={buttonsStyle}
											onClick={() =>
												handleDeleteImage(index)
											}
										>
											<Delete />
										</Button>
									</Tooltip>
									<span className="ml-3">
										{selectedImages &&
											selectedImages[index] &&
											(selectedImages[index]?.name ||
												selectedImages[index]?.split(
													/-(.*)/s
												)[1] ||
												"")}
									</span>
									<br />
								</>
							)}
						</>
					);
				})}
				<br />
				<Button
					variant="outlined"
					color="primary"
					onClick={() => {
						setSelectedImages([...selectedImages, null]);
					}}
				>
					Add image
				</Button>
			</>
		);
	}

	function renderFilePicker() {
		return (
			<>
				{selectedFiles?.map((_, index) => {
					return (
						<>
							<label htmlFor={"upload-file" + index} key={index}>
								<input
									style={{ display: "none" }}
									id={"upload-file" + index}
									name={"upload-file" + index}
									type="file"
									onChange={(e) => {
										const file = e.target.files[0];
										setSelectedFiles(
											(prevSelectedFiles) => {
												const newSelectedFiles = [
													...prevSelectedFiles,
												];
												newSelectedFiles[index] = file;
												return newSelectedFiles;
											}
										);
									}}
									key={index}
								/>
								<Button
									style={{ marginRight: "15px" }}
									color="secondary"
									component="span"
									variant="outlined"
								>
									{selectedFiles && selectedFiles[index]
										? "Change file " + (index + 1)
										: "Upload file " + (index + 1)}
								</Button>
							</label>
							{selectedFiles && selectedFiles[index] && (
								<>
									<Tooltip
										title={"Preview file " + (index + 1)}
									>
										<Button
											size="small"
											onClick={() =>
												setOpenPreviewDialog(index)
											}
											style={buttonsStyle}
										>
											<Visibility />
										</Button>
									</Tooltip>
									<PreviewDialog
										title={"Preview " + (index + 1)}
										open={openPreviewDialog === index}
										setOpen={setOpenPreviewDialog}
										file={
											selectedFiles &&
											selectedFiles[index]
										}
										src={
											selectedFiles &&
											selectedFiles[index] &&
											typeof selectedFiles[index] ===
												"string"
												? `${SERVER_URL}/${selectedFiles[index]}`
												: URL.createObjectURL(
														selectedFiles[index]
												  )
										}
									/>
									<Tooltip title="Delete">
										<Button
											size="small"
											style={buttonsStyle}
											onClick={() =>
												handleDeleteFile(index)
											}
										>
											<Delete />
										</Button>
									</Tooltip>
									<span className="ml-3">
										{selectedFiles &&
											selectedFiles[index] &&
											(selectedFiles[index]?.name ||
												selectedFiles[index]?.split(
													/-(.*)/s
												)[1] ||
												"")}
									</span>
									<br />
								</>
							)}
						</>
					);
				})}
				<br />
				<Button
					variant="outlined"
					color="primary"
					onClick={() => {
						setSelectedFiles([...selectedFiles, null]);
					}}
				>
					Add file
				</Button>
			</>
		);
	}

	if (isLoadingData) return <ContentSkeleton />;
	else
		return (
			<>
				<Card>
					<CardHeader title="Edit supplier">
						<CardHeaderToolbar>
							<Button
								onClick={() => saveSupplier()}
								variant="outlined"
								color="primary"
							>
								Save supplier
							</Button>
						</CardHeaderToolbar>
					</CardHeader>
					<CardBody>
						<TextField
							id={`fullName`}
							label="Full Name"
							value={supplier.fullName}
							onChange={handleChange("fullName")}
							InputLabelProps={{
								shrink: true,
							}}
							margin="normal"
							variant="outlined"
							required
						/>
						<div className="row d-flex align-items-center">
							<div className="col-md-6">
								<TextField
									id={`phone`}
									label="Phone"
									value={supplier?.phone}
									onChange={handleChange("phone")}
									InputLabelProps={{
										shrink: true,
									}}
									margin="normal"
									variant="outlined"
								/>
							</div>
							<div className="col-md-6">
								<TextField
									id={`email`}
									label="Email"
									value={supplier?.email}
									onChange={handleChange("email")}
									InputLabelProps={{
										shrink: true,
									}}
									margin="normal"
									variant="outlined"
								/>
							</div>
						</div>
						<div className="row d-flex align-items-center">
							<div className="col-md-6">
								<TextField
									id={`nif`}
									label="NIF"
									value={supplier.nif}
									onChange={handleChange("nif")}
									InputLabelProps={{
										shrink: true,
									}}
									margin="normal"
									variant="outlined"
								/>
							</div>
							<div className="col-md-6">
								<TextField
									id={`address`}
									label="Address"
									value={supplier.address}
									onChange={handleChange("address")}
									InputLabelProps={{
										shrink: true,
									}}
									margin="normal"
									variant="outlined"
								/>
							</div>
						</div>
						<div className="row d-flex align-items-center">
							<div className="col-md-6">
								<TextField
									id={`town`}
									label="Town"
									value={supplier.town}
									onChange={handleChange("town")}
									InputLabelProps={{
										shrink: true,
									}}
									margin="normal"
									variant="outlined"
								/>
							</div>
							<div className="col-md-6">
								<TextField
									id={`postalCode`}
									label="Postal Code"
									value={supplier.postalCode}
									onChange={handleChange("postalCode")}
									InputLabelProps={{
										shrink: true,
									}}
									margin="normal"
									variant="outlined"
								/>
							</div>
						</div>
						<div className="row d-flex align-items-center">
							<div className="col-md-6">
								<TextField
									id={`province`}
									label="Province"
									value={supplier.province}
									onChange={handleChange("province")}
									InputLabelProps={{
										shrink: true,
									}}
									margin="normal"
									variant="outlined"
								/>
							</div>
							<div className="col-md-6">
								<TextField
									id={`country`}
									label="Country"
									value={supplier.country}
									onChange={handleChange("country")}
									InputLabelProps={{
										shrink: true,
									}}
									margin="normal"
									variant="outlined"
								/>
							</div>
						</div>
						<TextField
							id={`web`}
							label="Web"
							value={supplier?.web}
							onChange={handleChange("web")}
							InputLabelProps={{
								shrink: true,
							}}
							margin="normal"
							variant="outlined"
						/>
						<br />
						<br />
						<div>Comments</div>
						<br />
						<Editor
							body={supplier?.comments || ""}
							setBody={(new_body) => {
								handleChangeEditor("comments", new_body);
							}}
							className="max-height"
							placeholder={"Enter comments here..."}
						/>
						<br />
						<br />
						<div>Images</div>
						<br />
						{renderImagePicker()}
						<br />
						<br />
						<br />
						<div>Files</div>
						<br />
						{renderFilePicker()}
					</CardBody>
					<CardHeader title="Contacts" sticky={false}>
						<CardHeaderToolbar>
							<button
								type="button"
								className="btn btn-primary"
								onClick={() => {
									setOpenEditContactDialog(true);
								}}
							>
								Add new
							</button>
						</CardHeaderToolbar>
					</CardHeader>
					<CardBody>
						{supplier?.contacts?.length > 0 && !refresh && (
							<Table
								data={getContactData(supplier?.contacts)}
								columns={contactColumns}
							/>
						)}
					</CardBody>
					<CardHeader
						title="Offered Artworks"
						sticky={false}
					></CardHeader>
					<CardBody>
						{supplier?.artworks?.length > 0 && (
							<Table
								data={getArtworkSupplierData(
									supplier?.artworks
								)}
								columns={artworkSuppliersColumns}
							/>
						)}
					</CardBody>
					<CardHeader title="Seeked Artworks" sticky={false}>
						<CardHeaderToolbar>
							<button
								type="button"
								className="btn btn-primary"
								onClick={() => {
									setOpenArtworkTableDialog(true);
								}}
							>
								Add new
							</button>
						</CardHeaderToolbar>
					</CardHeader>
					<CardBody>
						{supplier?.artworkDesires?.length > 0 && !refresh && (
							<Table
								data={getArtworkDesireData(
									supplier?.artworkDesires
								)}
								columns={artworkDesiresColumns}
							/>
						)}
					</CardBody>
				</Card>
				<Button
					onClick={handleBack}
					variant="outlined"
					style={{ marginRight: "20px" }}
				>
					Back
				</Button>
				<Button
					onClick={() => saveSupplier()}
					variant="outlined"
					color="primary"
					style={supplierId && { marginRight: "20px" }}
				>
					Save supplier
				</Button>
				{supplierId && (
					<>
						<MuiThemeProvider theme={theme}>
							<Button
								className="py-2"
								onClick={() => setOpenConfirmDialog(true)}
								variant="outlined"
								color="secondary"
							>
								Delete supplier
							</Button>
						</MuiThemeProvider>
						<ConfirmDialog
							title={
								"Are you sure you want to delete this supplier?"
							}
							open={openConfirmDialog}
							setOpen={setOpenConfirmDialog}
							onConfirm={() => {
								deleteSupplier(supplierId)
									.then((res) => {
										if (
											res.status === 204 ||
											res.status === 200
										) {
											postLog(
												loggedUser,
												LOGS.FIELDS.SUPPLIERS,
												`${LOGS.ACTIONS.DELETE}-${supplier?.fullName}`
											);
											alertSuccess({
												title: "Deleted!",
												customMessage:
													"Supplier deleted successfully.",
											});
											handleBack();
										}
									})
									.catch((error) => {
										alertError({
											error: error,
											customMessage:
												"Could not delete supplier.",
										});
									});
							}}
						/>
					</>
				)}
				<CommentDialog
					title={"Additional Comments"}
					open={openViewCommentDialog || openCommentDialog}
					setOpen={
						openViewCommentDialog
							? setOpenViewCommentDialog
							: setOpenCommentDialog
					}
					data={selectedArtworkSupplier?.comments}
					editable={openCommentDialog}
					onSave={(comments) => {
						let artworkDesires = [...supplier.artworkDesires];

						let index = artworkDesires.findIndex(
							(x) =>
								x.artwork === selectedArtworkSupplier?.artwork
						);
						artworkDesires[index] = {
							...artworkDesires[index],
							comments: comments,
						};

						setSupplier({
							...supplier,
							artworkDesires: artworkDesires,
						});

						setRefresh(true);
					}}
				/>
				<ImagePickerDialog
					title={"Images"}
					open={openViewImagesDialog || openImagePickerDialog}
					setOpen={
						openViewImagesDialog
							? setOpenViewImagesDialog
							: setOpenImagePickerDialog
					}
					data={
						openViewImagesDialog
							? selectedArtworkSupplier?.imagesURLs
							: [
									...(selectedArtworkSupplier?.imagesURLs ??
										[]),
									...(selectedArtworkSupplier?.images ?? []),
							  ]
					}
					editable={openImagePickerDialog}
					onSave={(images) => {
						let artworkDesires = [...supplier.artworkDesires];

						let index = artworkDesires.findIndex(
							(x) =>
								x.artwork === selectedArtworkSupplier?.artwork
						);
						artworkDesires[index] = {
							...artworkDesires[index],
							images: images?.filter((x) => x.name),
							imagesURLs: images?.filter((x) => !x.name),
						};

						setSupplier({
							...supplier,
							artworkDesires: artworkDesires,
						});

						setRefresh(true);
					}}
				/>
				<FilePickerDialog
					title={"Files"}
					open={openViewFilesDialog || openFilePickerDialog}
					setOpen={
						openViewFilesDialog
							? setOpenViewFilesDialog
							: setOpenFilePickerDialog
					}
					data={
						openViewFilesDialog
							? selectedArtworkSupplier?.filesURLs
							: [
									...(selectedArtworkSupplier?.filesURLs ??
										[]),
									...(selectedArtworkSupplier?.files ?? []),
							  ]
					}
					editable={openFilePickerDialog}
					onSave={(files) => {
						let artworkDesires = [...supplier.artworkDesires];

						let index = artworkDesires.findIndex(
							(x) =>
								x.artwork === selectedArtworkSupplier?.artwork
						);
						artworkDesires[index] = {
							...artworkDesires[index],
							files: files?.filter((x) => x.name),
							filesURLs: files?.filter((x) => !x.name),
						};

						setSupplier({
							...supplier,
							artworkDesires: artworkDesires,
						});

						setRefresh(true);
					}}
				/>
				<ArtworkTableDialog
					title={"Artworks"}
					open={openArtworkTableDialog}
					setOpen={setOpenArtworkTableDialog}
					data={artworks?.filter(
						(x) =>
							!supplier?.artworkDesires?.some(
								(y) => y.artwork === x._id
							) &&
							!x?.artworkSuppliers?.some(
								(x) => x.supplier === supplierId
							)
					)}
					onSelectRow={(artworkId) => {
						let artworkDesires = supplier.artworkDesires;
						const artworkSupplier = {
							...artworks
								?.find((x) => x._id === artworkId)
								.artworkSuppliers?.find(
									(x) => x.supplier === supplierId
								),
						};

						artworkDesires.push({
							artwork: artworkId,
							imagesURLs: artworkSupplier?.imagesURLs,
							filesURLs: artworkSupplier?.filesURLs,
							comments: artworkSupplier?.comments,
						});

						setSupplier({
							...supplier,
							artworkDesires: artworkDesires,
						});

						setRefresh(true);
					}}
				/>
				<ImageCarouselDialog
					open={openCarouselDialog}
					setOpen={setOpenCarouselDialog}
					images={carouselImages}
				/>
				<EditContactDialog
					open={openEditContactDialog}
					setOpen={setOpenEditContactDialog}
					data={selectedContact}
					onSave={(contact) => {
						let contacts = [...supplier?.contacts];

						if (selectedContact) {
							contacts[selectedContact?.id] = {
								...contacts[selectedContact?.id],
								...contact,
							};
						} else {
							contacts.push({
								...contact,
								id: contacts?.length,
							});
						}

						setSupplier({ ...supplier, contacts: contacts });
						setRefresh(true);
					}}
				/>
			</>
		);
}
