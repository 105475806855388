import {
	Button,
	createMuiTheme,
	MuiThemeProvider,
	TextField,
	Tooltip,
} from "@material-ui/core";
import {
	AddComment,
	AddPhotoAlternate,
	Delete,
	Launch,
	NoteAdd,
	RateReview,
	Visibility,
} from "@material-ui/icons";
import { Autocomplete } from "@material-ui/lab";
import React, { useEffect, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import {
	Card,
	CardBody,
	CardHeader,
	CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import { SERVER_URL } from "../../../../api";
import { getArtists } from "../../../../api/artist";
import {
	deleteArtwork,
	getArtworkById,
	getArtworks,
	postArtwork,
	updateArtwork,
} from "../../../../api/artwork";
import { getArtworkTypes } from "../../../../api/artworkType";
import { getCountries } from "../../../../api/country";
import { postLog } from "../../../../api/log";
import { getSuppliers } from "../../../../api/supplier";
import { LOGS } from "../../../../utils/constants";
import { checkIsEmpty, findSimilarArtworks } from "../../../../utils/helpers";
import { alertError, alertSuccess } from "../../../../utils/logger";
import ArtworkTableDialog from "../../../components/dialogs/ArtworkTableDialog";
import CommentDialog from "../../../components/dialogs/CommentDialog";
import ConfirmDialog from "../../../components/dialogs/ConfirmDialog";
import FilePickerDialog from "../../../components/dialogs/FilePickerDialog";
import ImageCarouselDialog from "../../../components/dialogs/ImageCarouselDialog";
import ImagePickerDialog from "../../../components/dialogs/ImagePickerDialog";
import PreviewDialog from "../../../components/dialogs/PreviewDialog";
import SupplierTableDialog from "../../../components/dialogs/SupplierTableDialog";
import ItemCountBadge from "../../../components/ItemCountBadge";
import Table, {
	buttonsStyle,
	substringFormatter,
} from "../../../components/tables/table";
import { useSkeleton } from "../../../hooks/useSkeleton";

// Create theme for delete button (red)
const theme = createMuiTheme({
	palette: {
		secondary: {
			main: "#F64E60",
		},
	},
});

export function getEmptyArtwork() {
	return {
		title: "",
		artworkType: "",
		artist: null,
		size: null,
		technique: null,
		year: null,
		imageURL: null,
		conservationStatus: null,
		signature: null,
		subject: null,
		originCountry: null,
		currentLocation: null,
		provenance: null,
		artworkSuppliers: [],
	};
}

export default function EditArtworksPage() {
	const [artwork, setArtwork] = useState(getEmptyArtwork());

	const [artworks, setArtworks] = useState([]);
	const [artists, setArtists] = useState([]);
	const [artworkTypes, setArtworkTypes] = useState([]);
	const [suppliers, setSuppliers] = useState([]);
	const [countries, setCountries] = useState([]);

	const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
	const [openSupplierTableDialog, setOpenSupplierTableDialog] = useState(
		false
	);

	const [selectedArtworks, setSelectedArtworks] = useState([]);
	const [openArtworkTableDialog, setOpenArtworkTableDialog] = useState(false);

	const [selectedArtworkSupplier, setSelectedArtworkSupplier] = useState(
		null
	);

	const [carouselImages, setCarouselImages] = useState([]);
	const [openCarouselDialog, setOpenCarouselDialog] = useState(false);

	const [openPreviewDialog, setOpenPreviewDialog] = useState(false);
	const [openCommentDialog, setOpenCommentDialog] = useState(false);
	const [openImagePickerDialog, setOpenImagePickerDialog] = useState(false);
	const [openFilePickerDialog, setOpenFilePickerDialog] = useState(false);

	const [selectedImage, setSelectedImage] = useState(null);

	const [refresh, setRefresh] = useState(null);

	const artworkId = useParams().id;
	const history = useHistory();
	const location = useLocation();

	const loggedUser = useSelector(
		(store) => store.authentication?.user,
		shallowEqual
	);

	const {
		isLoading: isLoadingData,
		disableLoading: disableLoadingData,
		ContentSkeleton,
	} = useSkeleton();

	useEffect(() => {
		getArtworks()
			.then((res) => {
				if (res.status === 200) {
					setArtworks(res.data);
				}
			})
			.catch((error) => {
				alertError({
					error: error,
					customMessage: "Could not get artworks.",
				});
				handleBack();
			});
		getArtists()
			.then((res) => {
				if (res.status === 200) {
					setArtists(res.data);
				}
			})
			.catch((error) => {
				alertError({
					error: error,
					customMessage: "Could not get artists.",
				});
				handleBack();
			});
		getArtworkTypes()
			.then((res) => {
				if (res.status === 200) {
					setArtworkTypes(res.data);
				}
			})
			.catch((error) => {
				alertError({
					error: error,
					customMessage: "Could not get artwork types",
				});
				handleBack();
			});
		getSuppliers()
			.then((res) => {
				if (res.status === 200) {
					setSuppliers(res.data);
				}
			})
			.catch((error) => {
				alertError({
					error: error,
					customMessage: "Could not get suppliers.",
				});
				handleBack();
			});
		getCountries()
			.then((res) => {
				if (res.status === 200) {
					setCountries(res.data);
				}
			})
			.catch((error) => {
				alertError({
					error: error,
					customMessage: "Could not get countries.",
				});
				handleBack();
			});
		if (!artworkId) {
			disableLoadingData();
			return;
		}
		getArtworkById(artworkId)
			.then((res) => {
				if (res.status === 200) {
					setArtwork({ ...artwork, ...res.data });
					disableLoadingData();
				}
			})
			.catch((error) => {
				alertError({
					error: error,
					customMessage: "Could not get artwork.",
				});
				handleBack();
			});
	}, [artworkId, disableLoadingData, history]);

	useEffect(() => {
		setRefresh(false);
	}, [refresh]);

	const handleBack = () => {
		const prevTablePage = location?.state?.page;

		history.push(
			`/artworks${prevTablePage ? "?page=" + prevTablePage : ""}`
		);
	};

	function handleSaveArtwork() {
		if (checkIsEmpty(artwork.title) || checkIsEmpty(artwork.artworkType)) {
			alertError({ error: "Title and type are required" });
			return;
		}
		const similarArtworks = findSimilarArtworks({
			artworks: artworks,
			artwork: artwork,
		});
		if (similarArtworks?.length > 0) {
			setSelectedArtworks(similarArtworks);
			setOpenArtworkTableDialog(true);
		} else saveArtwork();
	}

	async function saveArtwork() {
		if (!artworkId) {
			postArtwork(artwork, selectedImage)
				.then((res) => {
					if (res.status === 201) {
						postLog(
							loggedUser,
							LOGS.FIELDS.ARTWORKS,
							`${LOGS.ACTIONS.CREATE}-${artwork?.title}`
						);
						alertSuccess({
							title: "Saved!",
							customMessage: "Artwork successfully created.",
						});
						handleBack();
					}
				})
				.catch((error) => {
					alertError({
						error: error,
						customMessage: "Could not save artwork.",
					});
				});
		} else {
			updateArtwork(artworkId, artwork, selectedImage)
				.then((res) => {
					if (res.status === 200) {
						postLog(
							loggedUser,
							LOGS.FIELDS.ARTWORKS,
							`${LOGS.ACTIONS.UPDATE}-${artwork?.title}`
						);
						alertSuccess({
							title: "Saved!",
							customMessage: "Changes successfully saved.",
						});
						handleBack();
					}
				})
				.catch((error) => {
					alertError({
						error: error,
						customMessage: "Could not save changes.",
					});
				});
		}
	}

	const handleChange = (element) => (event) => {
		setArtwork({ ...artwork, [element]: event.target.value });
	};

	function getArtworkSupplierData(artworkSuppliers) {
		let data = [];
		for (let i = 0; i < artworkSuppliers?.length; ++i) {
			const elem = {};
			const artworkSupplier = artworkSuppliers[i];

			elem.fullName = suppliers?.find(
				(x) => x._id === artworkSupplier?.supplier
			)?.fullName;
			elem.comments = artworkSupplier?.comments;
			elem.id = artworkSupplier?.supplier;

			data = data.concat(elem);
		}
		return data;
	}

	const artworkSuppliersColumns = [
		{ dataField: "id", text: "", formatter: imageFormatter },
		{ dataField: "fullName", text: "Full Name", sort: true },
		{
			dataField: "comments",
			text: "Comments",
			sort: true,
			formatter: substringFormatter,
		},
		{ dataField: "id", text: "", formatter: buttonFormatter },
	];

	function imageFormatter(cell) {
		const elem = artwork.artworkSuppliers?.find((x) => x.supplier === cell);

		const images = (elem.imagesURLs || [])?.concat(elem?.images || []);

		return images && images?.length > 0 ? (
			<img
				src={
					typeof images[0] === "string"
						? SERVER_URL + "/" + images[0]
						: URL.createObjectURL(images[0])
				}
				alt="artwork"
				style={{ width: "50px", cursor: "zoom-in" }}
				onClick={() => {
					setCarouselImages(images);
					setOpenCarouselDialog(true);
				}}
			/>
		) : (
			<div />
		);
	}

	function buttonFormatter(cell) {
		const elem = artwork.artworkSuppliers?.find((x) => x.supplier === cell);

		return (
			<>
				<Tooltip
					title={!elem?.comments ? "Add comments" : "Edit comments"}
				>
					<Button
						style={buttonsStyle}
						size="small"
						onClick={() => {
							setOpenCommentDialog(true);
							setSelectedArtworkSupplier(elem);
						}}
					>
						{!elem?.comments ? <AddComment /> : <RateReview />}
						{elem?.comments && <ItemCountBadge howMany={1} />}
					</Button>
				</Tooltip>
				<Tooltip
					title={
						!elem?.imagesURLs?.length > 0 &&
						!elem?.images?.length > 0
							? "Add Images"
							: "Edit Images"
					}
				>
					<Button
						style={buttonsStyle}
						size="small"
						onClick={() => {
							setOpenImagePickerDialog(true);
							setSelectedArtworkSupplier(elem);
						}}
					>
						<AddPhotoAlternate />
						{(elem?.imagesURLs?.length > 0 ||
							elem?.images?.length > 0) && (
							<ItemCountBadge
								howMany={
									(elem?.imagesURLs?.length || 0) +
									(elem?.images?.length || 0)
								}
							/>
						)}
					</Button>
				</Tooltip>
				<Tooltip
					title={
						!elem?.filesURLs?.length > 0 && !elem?.files?.length > 0
							? "Add documentation"
							: "Edit documentation"
					}
				>
					<Button
						style={buttonsStyle}
						size="small"
						onClick={() => {
							setOpenFilePickerDialog(true);
							setSelectedArtworkSupplier(elem);
						}}
					>
						<NoteAdd />
						{(elem?.filesURLs?.length > 0 ||
							elem?.files?.length > 0) && (
							<ItemCountBadge
								howMany={
									(elem?.filesURLs?.length || 0) +
									(elem?.files?.length || 0)
								}
							/>
						)}
					</Button>
				</Tooltip>
				<Tooltip title={"See supplier"}>
					<Button
						style={buttonsStyle}
						size="small"
						onClick={() => {
							history.push(
								"/suppliers/" + elem?.supplier + "/edit"
							);
						}}
					>
						<Launch />
					</Button>
				</Tooltip>
				<Tooltip title="Delete">
					<Button
						style={buttonsStyle}
						size="small"
						onClick={() => {
							let artworkSuppliers = [
								...artwork.artworkSuppliers,
							];
							artworkSuppliers = artworkSuppliers.filter(
								(x, index) => x.supplier !== cell
							);
							setArtwork({
								...artwork,
								artworkSuppliers: artworkSuppliers,
							});
						}}
					>
						<Delete />
					</Button>
				</Tooltip>
			</>
		);
	}

	if (isLoadingData) return <ContentSkeleton />;
	else
		return (
			<>
				<Card>
					<CardHeader title="Edit artwork">
						<CardHeaderToolbar>
							<Button
								onClick={() => handleSaveArtwork()}
								variant="outlined"
								color="primary"
							>
								Save artwork
							</Button>
						</CardHeaderToolbar>
					</CardHeader>
					<CardBody>
						<TextField
							id={`fullName`}
							label="Title"
							value={artwork.title}
							onChange={handleChange("title")}
							InputLabelProps={{
								shrink: true,
							}}
							margin="normal"
							variant="outlined"
							required
						/>
						<Autocomplete
							id="autocomplete-artworkType"
							disablePortal
							disableClearable
							options={artworkTypes}
							getOptionLabel={(option) => option.title}
							value={
								artworkTypes?.find(
									(x) => x._id === artwork?.artworkType
								) || ""
							}
							onChange={(event, selected) => {
								setArtwork({
									...artwork,
									artworkType: selected ? selected._id : null,
								});
							}}
							renderInput={(params) => (
								<TextField
									{...params}
									label="Artwork Type"
									margin="normal"
									variant="outlined"
									InputLabelProps={{
										shrink: true,
									}}
									required
								/>
							)}
						/>
						<div className="row d-flex align-items-center">
							<div className="col-md-6">
								<Autocomplete
									id="autocomplete-artist"
									disablePortal
									options={artists}
									getOptionLabel={(option) => option.fullName}
									value={
										artists?.find(
											(x) => x._id === artwork?.artist
										) || ""
									}
									onChange={(event, selected) => {
										setArtwork({
											...artwork,
											artist: selected
												? selected._id
												: null,
										});
									}}
									renderInput={(params) => (
										<TextField
											{...params}
											label="Artist"
											margin="normal"
											variant="outlined"
											InputLabelProps={{
												shrink: true,
											}}
										/>
									)}
								/>
							</div>
							<div className="col-md-6">
								<TextField
									id={`size`}
									label="Size"
									value={artwork.size}
									onChange={handleChange("size")}
									InputLabelProps={{
										shrink: true,
									}}
									margin="normal"
									variant="outlined"
								/>
							</div>
						</div>
						<div className="row d-flex align-items-center">
							<div className="col-md-6">
								<TextField
									id={`size`}
									label="Technique"
									value={artwork.technique}
									onChange={handleChange("technique")}
									InputLabelProps={{
										shrink: true,
									}}
									margin="normal"
									variant="outlined"
								/>
							</div>
							<div className="col-md-6">
								<TextField
									id={`size`}
									label="Year"
									value={artwork.year}
									onChange={handleChange("year")}
									InputLabelProps={{
										shrink: true,
									}}
									margin="normal"
									variant="outlined"
								/>
							</div>
						</div>
						<div className="row d-flex align-items-center">
							<div className="col-md-6">
								<TextField
									id={`size`}
									label="Conservation Status"
									value={artwork.conservationStatus}
									onChange={handleChange(
										"conservationStatus"
									)}
									InputLabelProps={{
										shrink: true,
									}}
									margin="normal"
									variant="outlined"
								/>
							</div>
							<div className="col-md-6">
								<TextField
									id={`size`}
									label="Signature"
									value={artwork.signature}
									onChange={handleChange("signature")}
									InputLabelProps={{
										shrink: true,
									}}
									margin="normal"
									variant="outlined"
								/>
							</div>
						</div>
						<div className="row d-flex align-items-center">
							<div className="col-md-6">
								<Autocomplete
									id="autocomplete-country"
									disablePortal
									options={countries}
									getOptionLabel={(option) => option.name}
									value={
										countries?.find(
											(x) =>
												x._id === artwork?.originCountry
										) || ""
									}
									onChange={(event, selected) => {
										setArtwork({
											...artwork,
											originCountry: selected
												? selected._id
												: null,
										});
									}}
									renderInput={(params) => (
										<TextField
											{...params}
											label="Origin Country"
											margin="normal"
											variant="outlined"
											InputLabelProps={{
												shrink: true,
											}}
										/>
									)}
								/>
							</div>
							<div className="col-md-6">
								<TextField
									id={`subject`}
									label="Subject"
									value={artwork.subject}
									onChange={handleChange("subject")}
									InputLabelProps={{
										shrink: true,
									}}
									margin="normal"
									variant="outlined"
								/>
							</div>
						</div>
						<TextField
							id={`currentLocation`}
							label="Current Location"
							value={artwork.currentLocation}
							onChange={handleChange("currentLocation")}
							InputLabelProps={{
								shrink: true,
							}}
							margin="normal"
							variant="outlined"
						/>
						<TextField
							id={`provenance`}
							label="Provenance"
							value={artwork.provenance}
							onChange={handleChange("provenance")}
							InputLabelProps={{
								shrink: true,
							}}
							multiline
							rows={5}
							margin="normal"
							variant="outlined"
						/>
						<br />
						<br />
						<br />
						<label htmlFor={"upload-image"} className="ml-0">
							<input
								style={{ display: "none" }}
								id={"upload-image"}
								name={"upload-image"}
								type="file"
								accept={"image/*"}
								onChange={(e) => {
									setSelectedImage(e.target.files[0]);
								}}
							/>
							<Button
								style={{ marginRight: "15px" }}
								color="secondary"
								component="span"
								variant="outlined"
							>
								{selectedImage ||
								(artwork.imageURL && artwork.imageURL !== "")
									? "Change image"
									: "Upload image"}
							</Button>
						</label>
						{(selectedImage ||
							(artwork.imageURL && artwork.imageURL !== "")) && (
							<>
								<Tooltip title={"Preview"}>
									<Button
										size="small"
										onClick={() =>
											setOpenPreviewDialog(true)
										}
										style={{
											...buttonsStyle,
											marginRight: "15px",
										}}
									>
										<Visibility />
									</Button>
								</Tooltip>
								<PreviewDialog
									title={"Preview"}
									open={openPreviewDialog}
									setOpen={setOpenPreviewDialog}
									file={selectedImage}
									src={
										selectedImage
											? URL.createObjectURL(selectedImage)
											: `${SERVER_URL}/${artwork.imageURL}`
									}
								/>
								<span>
									{selectedImage
										? selectedImage?.name
										: artwork.imageURL &&
										  artwork.imageURL !== ""
										? artwork.imageURL?.split(/-(.*)/s)[1]
										: ""}
								</span>
								<Tooltip title={"Delete"}>
									<Button
										size="small"
										onClick={() => {
											setSelectedImage(null);
											setArtwork({
												...artwork,
												imageURL: null,
											});
										}}
									>
										<Delete />
									</Button>
								</Tooltip>
							</>
						)}
						<br />
					</CardBody>
					<CardHeader title="Suppliers" sticky={false}>
						<CardHeaderToolbar>
							<button
								type="button"
								className="btn btn-primary"
								onClick={() => {
									setOpenSupplierTableDialog(true);
								}}
							>
								Add new
							</button>
						</CardHeaderToolbar>
					</CardHeader>
					<CardBody>
						{artwork?.artworkSuppliers?.length > 0 && !refresh && (
							<Table
								data={getArtworkSupplierData(
									artwork?.artworkSuppliers
								)}
								columns={artworkSuppliersColumns}
							/>
						)}
					</CardBody>
				</Card>
				<Button
					onClick={handleBack}
					variant="outlined"
					style={{ marginRight: "20px" }}
				>
					Back
				</Button>
				<Button
					onClick={() => handleSaveArtwork()}
					variant="outlined"
					color="primary"
					style={artworkId && { marginRight: "20px" }}
				>
					Save artwork
				</Button>
				{artworkId && (
					<>
						<MuiThemeProvider theme={theme}>
							<Button
								className="py-2"
								onClick={() => setOpenConfirmDialog(true)}
								variant="outlined"
								color="secondary"
							>
								Delete artwork
							</Button>
						</MuiThemeProvider>
						<ConfirmDialog
							title={
								"Are you sure you want to delete this artwork?"
							}
							open={openConfirmDialog}
							setOpen={setOpenConfirmDialog}
							onConfirm={() => {
								deleteArtwork(artworkId)
									.then((res) => {
										if (
											res.status === 204 ||
											res.status === 200
										) {
											postLog(
												loggedUser,
												LOGS.FIELDS.ARTWORKS,
												`${LOGS.ACTIONS.DELETE}-${artwork?.title}`
											);
											alertSuccess({
												title: "Deleted!",
												customMessage:
													"Artwork deleted successfully.",
											});
											handleBack();
										}
									})
									.catch((error) => {
										alertError({
											error: error,
											customMessage:
												"Could not delete artwork.",
										});
									});
							}}
						/>
					</>
				)}
				<SupplierTableDialog
					title="Suppliers"
					open={openSupplierTableDialog}
					setOpen={setOpenSupplierTableDialog}
					data={suppliers.filter(
						(x) =>
							!artwork?.artworkSuppliers?.some(
								(y) => y.supplier === x._id
							)
					)}
					onSelectRow={(supplierId) => {
						let artworkSuppliers = artwork.artworkSuppliers;

						artworkSuppliers.push({ supplier: supplierId });

						setArtwork({
							...artwork,
							artworkSuppliers: artworkSuppliers,
						});
					}}
				/>
				<CommentDialog
					title={"Additional Comments"}
					open={openCommentDialog}
					setOpen={setOpenCommentDialog}
					data={selectedArtworkSupplier?.comments}
					onSave={(comments) => {
						let artworkSuppliers = [...artwork.artworkSuppliers];

						let index = artworkSuppliers.findIndex(
							(x) =>
								x.supplier === selectedArtworkSupplier?.supplier
						);
						artworkSuppliers[index] = {
							...artworkSuppliers[index],
							comments: comments,
						};

						setArtwork({
							...artwork,
							artworkSuppliers: artworkSuppliers,
						});

						setRefresh(true);
					}}
				/>
				<ImagePickerDialog
					title={"Images"}
					open={openImagePickerDialog}
					setOpen={setOpenImagePickerDialog}
					data={[
						...(selectedArtworkSupplier?.imagesURLs ?? []),
						...(selectedArtworkSupplier?.images ?? []),
					]}
					onSave={(images) => {
						let artworkSuppliers = [...artwork.artworkSuppliers];

						let index = artworkSuppliers.findIndex(
							(x) =>
								x.supplier === selectedArtworkSupplier?.supplier
						);
						artworkSuppliers[index] = {
							...artworkSuppliers[index],
							images: images?.filter((x) => x.name),
							imagesURLs: images?.filter((x) => !x.name),
						};

						setArtwork({
							...artwork,
							artworkSuppliers: artworkSuppliers,
						});

						setRefresh(true);
					}}
				/>
				<FilePickerDialog
					title={"Archivos"}
					open={openFilePickerDialog}
					setOpen={setOpenFilePickerDialog}
					data={[
						...(selectedArtworkSupplier?.filesURLs ?? []),
						...(selectedArtworkSupplier?.files ?? []),
					]}
					onSave={(files) => {
						let artworkSuppliers = [...artwork.artworkSuppliers];

						let index = artworkSuppliers.findIndex(
							(x) =>
								x.supplier === selectedArtworkSupplier?.supplier
						);
						artworkSuppliers[index] = {
							...artworkSuppliers[index],
							files: files?.filter((x) => x.name),
							filesURLs: files?.filter((x) => !x.name),
						};

						setArtwork({
							...artwork,
							artworkSuppliers: artworkSuppliers,
						});

						setRefresh(true);
					}}
				/>
				<ImageCarouselDialog
					open={openCarouselDialog}
					setOpen={setOpenCarouselDialog}
					images={carouselImages}
				/>
				<ArtworkTableDialog
					title={"Similar Artworks: " + artwork?.title}
					subtitle={
						<div className="bg-danger text-white p-3 mb-4">
							There are some artworks that have similar features!
							Check them before saving.
						</div>
					}
					open={openArtworkTableDialog}
					setOpen={setOpenArtworkTableDialog}
					data={selectedArtworks}
					allowAdd={false}
					onSave={() => saveArtwork()}
				/>
			</>
		);
}
