import { Button, Tooltip } from "@material-ui/core";
import { FormatListBulleted, ViewList } from "@material-ui/icons";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import React, { useEffect, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import {
	Card,
	CardBody,
	CardHeader,
	CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import { postLog } from "../../../../api/log";
import {
	deleteSupplier,
	getSupplierById,
	getSuppliers,
} from "../../../../api/supplier";
import { LOGS } from "../../../../utils/constants";
import { alertError, alertSuccess } from "../../../../utils/logger";
import ArtworkSupplierTableDialog from "../../../components/dialogs/ArtworkSupplierTableDialog";
import ConfirmDialog from "../../../components/dialogs/ConfirmDialog";
import Table, {
	buttonsStyle,
	dateFormatter,
	getCurrentTablePage,
} from "../../../components/tables/table";

function getData(suppliers) {
	let data = [];
	for (let i = 0; i < suppliers.length; ++i) {
		const elem = {};
		const supplier = suppliers[i];

		elem.fullName = supplier?.fullName;
		elem.town = supplier?.town;
		elem.province = supplier?.province;
		elem.createdAt = supplier?.createdAt;
		elem.id = supplier?._id;

		data = data.concat(elem);
	}
	return data;
}

export default function SuppliersPage() {
	const [data, setData] = useState([]);
	const [selectedSupplier, setSelectedSupplier] = useState(null);

	const [openConfirmDialog, setOpenConfirmDialog] = useState(null);
	const [openArtworkTableDialog, setOpenArtworkTableDialog] = useState(null);
	const [
		openArtworkDesireTableDialog,
		setOpenArtworkDesireTableDialog,
	] = useState(null);

	const [refresh, setRefresh] = useState(false);

	const location = useLocation();

	const history = useHistory();
	const loggedUser = useSelector(
		(store) => store.authentication?.user,
		shallowEqual
	);

	function buttonFormatter(cell) {
		const elem = data.find((item) => item._id === cell);
		return (
			<>
				<Tooltip title="Edit">
					<Button
						style={buttonsStyle}
						size="small"
						onClick={() => {
							history.push({
								pathname: "/suppliers/" + cell + "/edit",
								state: {
									page: getCurrentTablePage(location),
								},
							});
						}}
					>
						<EditIcon />
					</Button>
				</Tooltip>
				<Tooltip title="Offered Artworks">
					<Button
						style={buttonsStyle}
						size="small"
						onClick={() => {
							getSupplierById(cell)
								.then((res) => {
									if (res.status === 200) {
										setSelectedSupplier(res.data);
										setOpenArtworkTableDialog(true);
									}
								})
								.catch((error) => {
									alertError({
										error: error,
										customMessage:
											"Could not get artworks.",
									});
								});
						}}
					>
						<ViewList />
					</Button>
				</Tooltip>
				<Tooltip title="Seeked Artworks">
					<Button
						style={buttonsStyle}
						size="small"
						onClick={() => {
							getSupplierById(cell)
								.then((res) => {
									if (res.status === 200) {
										setSelectedSupplier(res.data);
										setOpenArtworkDesireTableDialog(true);
									}
								})
								.catch((error) => {
									alertError({
										error: error,
										customMessage:
											"Could not get artworks.",
									});
								});
						}}
					>
						<FormatListBulleted />
					</Button>
				</Tooltip>
				<Tooltip title="Delete">
					<Button
						style={buttonsStyle}
						size="small"
						onClick={() => {
							setSelectedSupplier(elem);
							setOpenConfirmDialog(true);
						}}
					>
						<DeleteIcon />
					</Button>
				</Tooltip>
			</>
		);
	}

	const columns = [
		{ dataField: "fullName", text: "Full Name", sort: true },
		{
			dataField: "town",
			text: "Town",
			sort: true,
			headerAlign: "center",
			align: "center",
		},
		{
			dataField: "province",
			text: "Province",
			sort: true,
			headerAlign: "center",
			align: "center",
		},
		{
			dataField: "createdAt",
			text: "Created At",
			formatter: dateFormatter,
			sort: true,
			headerAlign: "center",
			align: "center",
		},
		{ dataField: "id", text: "", formatter: buttonFormatter },
	];

	useEffect(() => {
		getSuppliers()
			.then((res) => {
				if (res.status === 200) {
					setData(res.data);
					setRefresh(false);
				}
			})
			.catch((error) => {
				alertError({
					error: error,
					customMessage: "Could not get suppliers.",
				});
			});
	}, [refresh]);

	return (
		<>
			<Card>
				<CardHeader title="Supplier list">
					<CardHeaderToolbar>
						<button
							type="button"
							className="btn btn-primary"
							onClick={() => {
								history.push({
									pathname: "/suppliers/edit",
									state: {
										page: getCurrentTablePage(location),
									},
								});
							}}
						>
							Add new
						</button>
					</CardHeaderToolbar>
				</CardHeader>
				<CardBody>
					<Table data={getData(data)} columns={columns} />
					<ConfirmDialog
						title={"Are you sure you want to delete this supplier?"}
						open={openConfirmDialog}
						setOpen={setOpenConfirmDialog}
						onConfirm={() => {
							deleteSupplier(selectedSupplier?._id)
								.then((res) => {
									if (
										res.status === 204 ||
										res.status === 200
									) {
										postLog(
											loggedUser,
											LOGS.FIELDS.SUPPLIERS,
											`${LOGS.ACTIONS.DELETE}-${selectedSupplier?.fullName}`
										);
										alertSuccess({
											title: "Deleted!",
											customMessage:
												"Supplier deleted successfully.",
										});
										setRefresh(true);
									}
								})
								.catch((error) => {
									alertError({
										error: error,
										customMessage:
											"Could not delete supplier.",
									});
								});
						}}
					/>
					<ArtworkSupplierTableDialog
						title={
							selectedSupplier?.fullName +
							": " +
							(openArtworkTableDialog
								? "Offered Artworks"
								: openArtworkDesireTableDialog
								? "Seeked Artworks"
								: "")
						}
						open={
							openArtworkTableDialog ||
							openArtworkDesireTableDialog
						}
						setOpen={
							openArtworkTableDialog
								? setOpenArtworkTableDialog
								: setOpenArtworkDesireTableDialog
						}
						data={selectedSupplier}
						artworkSupplierTable={openArtworkTableDialog}
						desireTable={openArtworkDesireTableDialog}
					/>
				</CardBody>
			</Card>
		</>
	);
}
